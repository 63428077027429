button,
select,
html,
textarea,
input,
md-select-value,
md-option {
  font-family: 'Roboto', sans-serif;
}
body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  background-color: #EEF4F9;
}
body.show-banner {
  margin-top: 120px;
  transition: all 0.25s ease-in-out;
}
body.md-dialog-is-showing {
  overflow: visible !important;
}
body.auth-screen,
body.admin-screen {
  margin-top: 0px;
}
body.admin-screen {
  background-color: #ebebeb;
}
body.admin-screen [ui-view="main"] {
  padding-top: 80px;
  width: calc(100% - 220px);
  margin-left: 220px;
}
body.admin-screen [ui-view="main"] md-content {
  width: calc(100% - 60px);
  margin-left: 15px;
  background-color: white;
  box-shadow: 0px 0px 3px 2px #a3a3a3;
}
body:not(.admin) {
  margin-top: 80px;
}
md-content {
  background-color: transparent;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
}
h1 {
  font-wieght: 500;
  font-size: 24px;
  line-height: 24px;
  margin: 0px;
  color: #1664D4;
}
h2 {
  font-size: 36px;
  line-height: 36px;
}
h3 {
  font-size: 24px;
  line-height: 24px;
}
p {
  font-weight: 300;
  font-size: 18px;
  color: #706F6F;
  line-height: 24px;
}
[role="button"] {
  outline: none;
}
.auth-screen .header,
.auth-screen .footer {
  display: none;
}
.admin-screen .footer {
  display: none;
}
.display-flex {
  display: flex;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.text-center {
  text-align: center;
}
@media (max-width: 992px) {
  body {
    margin-top: 0px !important;
  }
}
@media (max-width: 960px) {
  h1 {
    font-size: 30px;
    line-height: 30px;
  }
  h2 {
    font-size: 24px;
    line-height: 24px;
  }
  h3 {
    font-size: 18px;
    line-height: 18px;
  }
  p {
    font-size: 16px;
    line-height: 22px;
  }
  [ui-view="main"] {
    padding: 0px !important;
    padding-top: 50px !important;
    width: 100% !important;
    margin-left: 0px !important;
    min-height: calc(50%);
  }
}
.container {
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
}
.admin md-content.Page-container {
  margin: 15px;
  background-color: white;
}
.admin .add-new {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
@media (max-width: 992px) {
  body:not(.admin) .container {
    max-width: 767px;
  }
}
@media (max-width: 767px) {
  body:not(.admin) .container {
    max-width: 100%;
  }
}
body.admin .white-back {
  background-color: white;
  margin: 15px;
}
body.admin.users md-content.Page-container,
body.admin.books md-content.Page-container {
  max-width: 900px;
  margin: 56px auto;
  padding: 15px;
}
body.admin:not(.auth-screen) [ui-view="main"] {
  padding-top: 80px;
  padding-left: 220px;
  width: calc(100% - 220px);
  min-height: calc(100% - 80px);
  background-color: #ececec;
  padding-bottom: 1px;
}
@media (max-width: 1170px) {
  body.admin .container {
    max-width: 960px;
  }
}
@media (max-width: 960px) {
  body.admin .container {
    max-width: 600px;
  }
  body.admin .white-back {
    margin-top: 0px;
  }
  body.admin h1 {
    font-size: 24px;
    line-height: 50px;
    text-align: center;
  }
}
@media (max-width: 600px) {
  body.admin .container {
    max-width: 480px;
  }
}
@media (max-width: 480px) {
  body.admin .container {
    max-width: 100%;
  }
}
