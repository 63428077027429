.admin.login .Page-container {
  margin: 0px;
  background-color: #EEF4F9;
}
.Login-formContainer {
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 80px;
  max-width: 440px;
  background: #FFFFFF;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 3px;
}
.Login-formContainer .login-header {
  background-color: #1664D4;
  height: 130px;
  width: 100%;
  padding: 20px 40px;
  border-radius: 3px 3px 0 0;
}
.Login-formContainer .login-header h1 {
  font-size: 24px;
  color: #FFFFFF;
  margin: 0px;
}
.Login-formContainer .login-center {
  padding: 30px 40px;
  width: 100%;
}
.Login-formContainer .login-footer img {
  width: 210px;
  margin: 0 auto;
  margin-bottom: 50px;
}
