button,
select,
html,
textarea,
input,
md-select-value,
md-option {
  font-family: 'Roboto', sans-serif;
}
body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  background-color: #EEF4F9;
}
body.show-banner {
  margin-top: 120px;
  transition: all 0.25s ease-in-out;
}
body.md-dialog-is-showing {
  overflow: visible !important;
}
body.auth-screen,
body.admin-screen {
  margin-top: 0px;
}
body.admin-screen {
  background-color: #ebebeb;
}
body.admin-screen [ui-view="main"] {
  padding-top: 80px;
  width: calc(100% - 220px);
  margin-left: 220px;
}
body.admin-screen [ui-view="main"] md-content {
  width: calc(100% - 60px);
  margin-left: 15px;
  background-color: white;
  box-shadow: 0px 0px 3px 2px #a3a3a3;
}
body:not(.admin) {
  margin-top: 80px;
}
md-content {
  background-color: transparent;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
}
h1 {
  font-wieght: 500;
  font-size: 24px;
  line-height: 24px;
  margin: 0px;
  color: #1664D4;
}
h2 {
  font-size: 36px;
  line-height: 36px;
}
h3 {
  font-size: 24px;
  line-height: 24px;
}
p {
  font-weight: 300;
  font-size: 18px;
  color: #706F6F;
  line-height: 24px;
}
[role="button"] {
  outline: none;
}
.auth-screen .header,
.auth-screen .footer {
  display: none;
}
.admin-screen .footer {
  display: none;
}
.display-flex {
  display: flex;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.text-center {
  text-align: center;
}
@media (max-width: 992px) {
  body {
    margin-top: 0px !important;
  }
}
@media (max-width: 960px) {
  h1 {
    font-size: 30px;
    line-height: 30px;
  }
  h2 {
    font-size: 24px;
    line-height: 24px;
  }
  h3 {
    font-size: 18px;
    line-height: 18px;
  }
  p {
    font-size: 16px;
    line-height: 22px;
  }
  [ui-view="main"] {
    padding: 0px !important;
    padding-top: 50px !important;
    width: 100% !important;
    margin-left: 0px !important;
    min-height: calc(50%);
  }
}
.admin .admin-sidebar {
  position: fixed;
  width: 220px;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: #0F242C;
  box-sizing: border-box;
  z-index: 11;
}
.admin .admin-sidebar md-content {
  height: 100%;
}
.admin .admin-sidebar h1 {
  padding-left: 0px;
  color: white;
}
.admin .admin-sidebar .logo-top {
  background-color: white;
  width: 100%;
  height: 80px;
}
.admin .admin-sidebar .logo-top img {
  height: auto;
  margin: 0px auto;
  padding: 19px 5px;
  display: block;
  width: 190px;
}
.admin .admin-sidebar ul {
  padding-left: 0px;
  margin-top: 0px;
}
.admin .admin-sidebar li a {
  font-family: Roboto-Medium;
  font-size: 14px;
  color: #BEC3C7;
  line-height: 40px;
  padding: 30px 0;
  transition: all 0.25s ease-in-out;
  width: 100%;
  margin: 0px;
  padding: 8px 30px;
  text-align: left;
  border-radius: 0px;
  text-transform: none;
}
.admin .admin-sidebar li.current-view > a {
  color: white;
  background-color: #FB6830;
}
.admin .admin-sidebar li:hover > a {
  color: white;
  background-color: #fc8c62;
}
.admin .admin-sidebar li ul {
  display: none;
}
.admin .admin-sidebar li ul li a {
  color: #999;
  line-height: 30px;
  height: 30px;
  min-height: 30px;
  padding-left: 20px;
}
.admin .admin-sidebar li ul li md-icon {
  font-size: 6px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  display: block;
  float: left;
  text-align: center;
}
.admin .admin-sidebar li.current-view ul {
  display: block;
}
.admin .admin-sidebar .stick-to-bottom {
  position: absolute;
  bottom: 20px;
  left: 0px;
  width: 100%;
}
.admin .admin-sidebar .user {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.admin .admin-sidebar .user .avatar {
  margin: auto;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.admin .admin-sidebar .user .signed-in-as {
  font-size: 12px;
  color: #CCCCCC;
  text-align: center;
  margin-bottom: 2px;
}
.admin .admin-sidebar .user .name {
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
}
.admin .admin-sidebar .user .md-button {
  margin-top: 10px;
}
@media (max-width: 960px) {
  .admin .container {
    max-width: 600px;
  }
  .admin .admin-sidebar {
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    padding-top: 50px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
  }
  .admin.nav-open .admin-sidebar {
    opacity: 1;
    visibility: visible;
  }
}
