button,
select,
html,
textarea,
input,
md-select-value,
md-option {
  font-family: 'Roboto', sans-serif;
}
body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  background-color: #EEF4F9;
}
body.show-banner {
  margin-top: 120px;
  transition: all 0.25s ease-in-out;
}
body.md-dialog-is-showing {
  overflow: visible !important;
}
body.auth-screen,
body.admin-screen {
  margin-top: 0px;
}
body.admin-screen {
  background-color: #ebebeb;
}
body.admin-screen [ui-view="main"] {
  padding-top: 80px;
  width: calc(100% - 220px);
  margin-left: 220px;
}
body.admin-screen [ui-view="main"] md-content {
  width: calc(100% - 60px);
  margin-left: 15px;
  background-color: white;
  box-shadow: 0px 0px 3px 2px #a3a3a3;
}
body:not(.admin) {
  margin-top: 80px;
}
md-content {
  background-color: transparent;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
}
h1 {
  font-wieght: 500;
  font-size: 24px;
  line-height: 24px;
  margin: 0px;
  color: #1664D4;
}
h2 {
  font-size: 36px;
  line-height: 36px;
}
h3 {
  font-size: 24px;
  line-height: 24px;
}
p {
  font-weight: 300;
  font-size: 18px;
  color: #706F6F;
  line-height: 24px;
}
[role="button"] {
  outline: none;
}
.auth-screen .header,
.auth-screen .footer {
  display: none;
}
.admin-screen .footer {
  display: none;
}
.display-flex {
  display: flex;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.text-center {
  text-align: center;
}
@media (max-width: 992px) {
  body {
    margin-top: 0px !important;
  }
}
@media (max-width: 960px) {
  h1 {
    font-size: 30px;
    line-height: 30px;
  }
  h2 {
    font-size: 24px;
    line-height: 24px;
  }
  h3 {
    font-size: 18px;
    line-height: 18px;
  }
  p {
    font-size: 16px;
    line-height: 22px;
  }
  [ui-view="main"] {
    padding: 0px !important;
    padding-top: 50px !important;
    width: 100% !important;
    margin-left: 0px !important;
    min-height: calc(50%);
  }
}
.admin .admin-header {
  display: block;
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0px;
  left: 0px;
  background-color: white;
  padding-left: 220px;
  box-sizing: border-box;
  z-index: 10;
  box-shadow: none;
}
.admin .admin-header md-content {
  padding: 13px 15px;
}
.admin .admin-header h1 {
  margin: 0px;
  line-height: 80px;
  padding-left: 15px;
  color: #3c3c3b;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 30px;
}
@media (max-width: 960px) {
  .admin .admin-header .container {
    max-width: 600px;
  }
  .admin .admin-header .admin-header {
    padding-left: 0px;
    height: 50px;
    z-index: 20;
    background-color: #0F242C;
  }
  .admin .admin-header .admin-header .logo img {
    margin: 15px;
    height: 20px;
  }
  .admin .admin-header .admin-header .nav-toggle {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  .admin .admin-header .admin-header .nav-toggle md-icon {
    color: white;
    font-size: 30px;
    width: 50px;
    height: 50px;
    line-height: 48px;
  }
  .admin .admin-header .admin-header .nav-toggle:hover {
    opacity: 0.7;
  }
  .admin .admin-header .fa-times,
  .admin .admin-header .fa-bars {
    transition: all 0.25s ease-in-out;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .admin .admin-header .fa-times {
    opacity: 0;
    visibility: hidden;
  }
  .admin .admin-header.nav-open {
    position: relative;
  }
  .admin .admin-header.nav-open .fa-times {
    opacity: 1;
    visibility: visible;
  }
  .admin .admin-header.nav-open .fa-bars {
    opacity: 0;
    visibility: hidden;
  }
}
