button,
select,
html,
textarea,
input,
md-select-value,
md-option {
  font-family: 'Roboto', sans-serif;
}
body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  background-color: #EEF4F9;
}
body.show-banner {
  margin-top: 120px;
  transition: all 0.25s ease-in-out;
}
body.md-dialog-is-showing {
  overflow: visible !important;
}
body.auth-screen,
body.admin-screen {
  margin-top: 0px;
}
body.admin-screen {
  background-color: #ebebeb;
}
body.admin-screen [ui-view="main"] {
  padding-top: 80px;
  width: calc(100% - 220px);
  margin-left: 220px;
}
body.admin-screen [ui-view="main"] md-content {
  width: calc(100% - 60px);
  margin-left: 15px;
  background-color: white;
  box-shadow: 0px 0px 3px 2px #a3a3a3;
}
body:not(.admin) {
  margin-top: 80px;
}
md-content {
  background-color: transparent;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
}
h1 {
  font-wieght: 500;
  font-size: 24px;
  line-height: 24px;
  margin: 0px;
  color: #1664D4;
}
h2 {
  font-size: 36px;
  line-height: 36px;
}
h3 {
  font-size: 24px;
  line-height: 24px;
}
p {
  font-weight: 300;
  font-size: 18px;
  color: #706F6F;
  line-height: 24px;
}
[role="button"] {
  outline: none;
}
.auth-screen .header,
.auth-screen .footer {
  display: none;
}
.admin-screen .footer {
  display: none;
}
.display-flex {
  display: flex;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.text-center {
  text-align: center;
}
@media (max-width: 992px) {
  body {
    margin-top: 0px !important;
  }
}
@media (max-width: 960px) {
  h1 {
    font-size: 30px;
    line-height: 30px;
  }
  h2 {
    font-size: 24px;
    line-height: 24px;
  }
  h3 {
    font-size: 18px;
    line-height: 18px;
  }
  p {
    font-size: 16px;
    line-height: 22px;
  }
  [ui-view="main"] {
    padding: 0px !important;
    padding-top: 50px !important;
    width: 100% !important;
    margin-left: 0px !important;
    min-height: calc(50%);
  }
}
.u-center {
  text-align: center;
}
.hidden {
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
}
.md-button,
.btn {
  padding: 0 10px;
  min-width: 40px;
}
.md-button.md-raised,
.btn.md-raised {
  height: 40px;
  line-height: 20px;
  width: auto;
  padding: 10px 30px;
  border-radius: 0px;
}
.md-button span,
.btn span {
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
}
.md-button.md-raised:not([disabled]),
.btn.md-raised:not([disabled]) {
  box-shadow: none;
}
.md-button.md-primary:not(.md-raised),
.btn.md-primary:not(.md-raised) {
  color: black;
  text-transform: none;
}
.md-button.md-primary.md-raised,
.btn.md-primary.md-raised {
  color: white;
  background-color: #AFDD32;
}
.md-button.md-primary.md-raised:not([disabled]):hover,
.btn.md-primary.md-raised:not([disabled]):hover {
  background-color: #c0e45e;
}
.md-button.md-secondary.md-raised,
.btn.md-secondary.md-raised {
  color: white;
  background-color: #FB6830;
}
.md-button.md-secondary.md-raised:not([disabled]):hover,
.btn.md-secondary.md-raised:not([disabled]):hover {
  background-color: #fc8c62;
}
.md-button.md-tertiary.md-raised,
.btn.md-tertiary.md-raised {
  color: white;
  background-color: #CDCDCD;
}
.md-button.md-tertiary.md-raised:not([disabled]):hover,
.btn.md-tertiary.md-raised:not([disabled]):hover {
  background-color: #e7e7e7;
}
md-input-container {
  margin: 10px 0 0 0;
}
md-checkbox {
  margin-top: 10px;
  margin-bottom: 15px;
}
md-input-container > md-select {
  margin-bottom: 15px;
}
md-table-container .center-column {
  white-space: nowrap !important;
  text-align: center !important;
}
md-table-container .clickable {
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
md-table-container .clickable:hover {
  background-color: #d9e8ff;
}
md-table-container th span {
  font-size: 14px;
  color: black;
}
md-table-container td {
  font-size: 15px !important;
  font-weight: 400 !important;
}
md-table-container td.md-cell {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
md-table-container table {
  border-bottom: 1px solid #ddd;
}
md-table-container table.md-table:not(.md-row-select) td.md-cell:first-child,
md-table-container table.md-table:not(.md-row-select) th.md-column:first-child {
  padding-left: 0px;
}
md-table-container table.md-table:not(.md-row-select) td.md-cell:last-child,
md-table-container table.md-table:not(.md-row-select) th.md-column:last-child {
  padding-right: 0px;
}
md-table-container td button {
  margin: 15px 5px 15px 0 !important;
}
md-dialog {
  width: 100%;
  max-width: 600px;
}
md-dialog .dialog-close {
  cursor: pointer;
  text-align: center;
}
md-toolbar.md-default-theme:not(.md-menu-toolbar),
md-toolbar:not(.md-menu-toolbar) {
  background-color: #1664D4;
}
@media (max-width: 960px) {
  .md-button {
    margin: 0px;
    line-height: 20px;
  }
  .md-button span {
    font-size: 16px;
  }
}
